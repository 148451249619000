import {Text} from "@chakra-ui/core";
import styled from "@emotion/styled";

const Heading = styled(Text)`
  font-family: "Recoleta-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    sans-serif !important;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.5px;
`;

export default Heading;